<template>
  <div>
    <Card>
      <template #header>Albümler</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <b-form-group>
              <b-button variant="primary" @click="addGallery = true">
                Yeni Albüm Ekle</b-button
              ></b-form-group
            >
          </div>
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Albüm Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end mx-4">
          <router-link :to="`albumedit/${item._id.$oid}`">
            <button class="btn p-0">
              <i class="flaticon-eye text-dark text-md"></i>
            </button>
          </router-link>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
         
        </div>
      </template>
    </List>


        <!--Multiple Gallery -->
        <b-modal v-model="addGallery" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni Albüm Bilgileri</h4>
      </template>
      <div class="form-group">
        <label for="aciklama">Albüm Adı</label>
        <input type="text" class="form-control" id="aciklama" v-model="albumname"/>
      </div>
      <div class="form-group">
        <label for="aciklama">Albüm Tarihi</label>
        <input type="date" class="form-control" id="aciklama" v-model="albumdate" />
      </div>
      <div class="form-group">
        <label for="type">Albüm Yeri</label>
        <select name="" id="type" class="form-control" v-model="albumlocation">
          <option value="" selected>Seçiniz</option>
          <option value="Tapu">Tapu</option>
          <option value="Ziyaret">Ziyaret</option>
          <option value="Sikayet">Şikayet</option>
          <option value="Bankamdan">Bankadan</option>
        </select>
      </div>
      <div class="form-group">
        <label for="aciklama">Açıklama</label>
        <textarea
          name=""
          id="aciklama"
          rows="3"
          class="form-control"
          v-model="albumdescription"
        ></textarea>
      </div>
      

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addGallery = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addAlbum" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <!--Multiple Gallery And-->
    <DeleteOperation       
      :url="deleteOperationUrl"
      :params="deleteIds"
      modalid="deleteAlbum"
      @updateList="getList"

  />

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
export default {
  name: "systemUsers",
  data() {
    return {
      addMultipleGallery: false,
      addGallery: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "albumname" },
        { text: "Tarih", value: "albumdate" },
        { text: "Açıklama", value: "albumdescription" },
        { text: "Yer", value: "albumlocation" },
        { text: "İşlemler", value: "action" },
      ],
      items: [
      ],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      albumdate:"",
      albumdescription:"",
      albumlocation:"",
      albumname:""
    };
  },
  
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/album?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
            this.items = res.response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        }); 
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `property/album`;
      this.deleteIds = `?user_token=${this.myUser}&album_id=${item._id.$oid}`;
      this.$bvModal.show('modal-deleteOperationdeleteAlbum');
    },
    addAlbum(){
      let data = {
        albumdate: this.albumdate,
        albumdescription:this.albumdescription,
        albumlocation:this.albumlocation,
        albumname:this.albumname,
        property_id : this.$route.params.id,
        user_token : this.myUser
      };
      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key]);
      }
      this.$ApiService
      .post("property/album",data)
      .then((res)=> {
        this.$generateNotification(this,"success","Albüm Başarıyla Eklenmiştir.");
        this.addGallery = false
        this.getList()
      })
      .catch((err) => {
        console.log(err)
        this.$generateNotification(this,"error","Hatırlatma Eklenemedi.");
      })
    }

  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed:{
    ...mapGetters(["myUser"])
  }
};
</script>
